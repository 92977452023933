<template>
  <div class="home">
    <h1>Üdvözöllek!</h1>
    <p>
      A játék során a már több mint 20 éve bezárt Karancs Szálló egyik szobájába juthattok be, ahol paranormális
      jelenségeket észleltek. <br/>
      A legenda szerint itt kísért egy fiatal olasz-magyar lány szelleme, akinek tragikus sorsa a hotel falai között
      teljesedett be.
      A lányt utolsó napján barátja meglepetésként látogatta volna meg és kérte volna meg a kezét, de érkezésekor már csak élettelen
      testét találta.
      A lány halála után a szobájába már vendégek nem érkeztek, csak a hotel bezárása után random bulik helyszíne volt. <br/>
      A ti feladatotok, hogy kiderítsétek a halálának körülményeit, ezzel felszabadítva a lelkét, ám a szoba tele van
      olyan nyomokkal, amelyek elterelik a figyelmeteket – nehezítve ezzel a rejtély megoldását.<br/>
      Mindössze egy óra áll rendelkezésetekre, bátor kalandorok, mert ettől több időt szellemek ilyen közeli jelenlétében nem szabad tartózkodni.
    </p>

    <p><strong>Fontos tudnivalók:</strong>
      <ul>
        <li>A bőröndötökben minden szükséges eszköz benne van.</li>
        <li>Ne másszatok fel semmire, mert balesetveszélyes!</li>
        <li>Ne írjatok semmire!</li>
        <li>A falról, tárgyakról ne szedjetek le semmit!</li>
        <li>A tévé működik, nézzetek nyugodtan filmeket, sorozatokat, híreket.</li>
        <li>Az óra elindult!</li>
      </ul>
      Sikeres játékot kívánok! :)
    </p>

    <h1>A lány lelkének felszabadítása</h1>
    <p>Ahhoz, hogy felszabadítsátok a lelkét, az alábbi rejtélyekre kell a válaszokat megtalálnotok! Akkor áll meg az óra, mikor az összes helyes választ megadtátok!</p>
    <form @submit.prevent="checkAnswers">
      <div v-for="(question, index) in questions" v-bind:key="index" class="question">
        <p>{{ question.question }}</p>
        <select v-if="question.inputType === 'select'" v-model="question.answer" required>
          <option v-for="(option, index) in question.options" v-bind:key="index">{{ option }}</option>
        </select>
        <input v-else :type="question.inputType" v-model="question.answer" required/>
      </div>
      <button type="submit">Ellenőrzés</button>
    </form>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  async mounted () {
    const url = 'https://api.sheetmonkey.io/form/ijpKELWHZc7hSrEo19WPmA'
    const body = {
      timestamp: new Date().toLocaleString()
    }
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    console.log(response)
  },
  data () {
    return {
      questions: [
        {
          question: 'Mi a csapatnevetek?',
          inputType: 'team',
          answer: ''
        },
        {
          question: '1. Mi a lány keresztneve?',
          rightAnswer: 'Elena',
          inputType: 'text',
          answer: ''
        },
        {
          question: '2. Mi köti össze a lány keresztnevét és a vezetéknevét?',
          rightAnswer: 'De',
          inputType: 'text',
          answer: ''
        },
        {
          question: '3. Mi a lány vezetékneve?',
          rightAnswer: 'Bortoli',
          inputType: 'text',
          answer: ''
        },
        {
          question: '4. Mi a fiú keresztneve?',
          rightAnswer: 'Francesco',
          inputType: 'text',
          answer: ''
        },
        {
          question: '5. Mi a fiú vezetékneve?',
          rightAnswer: 'Russo',
          inputType: 'text',
          answer: ''
        },
        {
          question: '6. Melyik évben halt meg a lány?',
          rightAnswer: 1992,
          inputType: 'number',
          answer: 1900
        },
        {
          question: '7. Melyik hónapban halt meg a lány?',
          rightAnswer: 'május',
          inputType: 'select',
          options: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember', 'október', 'november', 'december'],
          answer: ''
        },
        {
          question: '8. Hol kérték volna meg a kezét?',
          rightAnswer: 'Positano',
          inputType: 'text',
          answer: ''
        },
        {
          question: '9. Mi volt az utolsó üzenete a fiú számára?',
          rightAnswer: 'Szeretlekdeelkellettmennem',
          inputType: 'text',
          answer: ''
        }
      ]
    }
  },
  methods: {
    checkAnswer (question) {
      switch (question.inputType) {
        case 'text': {
          const plainAnswer = question.answer.trim().toLowerCase().replace(/\s/g, '').replace(',', '')
          return plainAnswer === question.rightAnswer.toLowerCase()
        }
        case 'number': {
          const plainAnswer = parseInt(question.answer)
          return question.rightAnswer === plainAnswer
        }
        case 'select':
          return question.rightAnswer === question.answer
        default:
          return true
      }
    },
    async checkAnswers () {
      const checks = this.questions.map(question => this.checkAnswer(question))
      const correct = !checks.includes(false)
      console.log(checks)
      console.log(correct)

      const url = 'https://api.sheetmonkey.io/form/ijpKELWHZc7hSrEo19WPmA'
      const body = {
        timestamp: new Date().toLocaleString(),
        team: this.questions[0].answer
      }
      for (let i = 1; i < this.questions.length; i++) {
        const question = this.questions[i]
        body[question.question] = question.answer
      }

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
      console.log(response)
      if (correct) {
        this.$swal({
          icon: 'success',
          title: 'Gratulálok!',
          text: 'Sikerült mindegyik kérdésre helyes választ adnotok! Elena lelke is békében nyugodhat most már, hogy minden titokra fény került! :)'
        })
      } else {
        this.$swal({
          icon: 'error',
          title: 'Hmmm...',
          text: 'Valam még nem jó... Biztosak vagytok minden válaszban?'
        })
      }
    }
  }
}
</script>

<style>

html {
  --s: 150px; /* control the size*/
  --c1: #a09879;
  --c2: #343d4b;

  --_g: var(--c1) 6.1%, var(--c2) 6.4% 18.6%, var(--c1) 18.9% 31.1%,
  var(--c2) 31.4% 43.6%, var(--c1) 43.9% 56.1%, var(--c2) 56.4% 68.6%, #0000 68.9%;
  background: radial-gradient(var(--s) at 100% 0, var(--_g)),
  radial-gradient(var(--s) at 0 0, var(--_g)),
  radial-gradient(var(--s) at 0 100%, var(--_g)),
  radial-gradient(var(--s) at 100% 100%, var(--_g)) var(--c1);
  background-size: var(--s) var(--s);
  display: flex;
  justify-content: center;
  align-items: center;
}

.home {
  background-color: #d7d3c2;
  margin: 3rem 1rem;
}

h1 {
  padding: 2rem 2rem 0 2rem;
}

p {
  font-size: 1.3rem;
  text-align: justify;
  padding: 1rem 2rem 0 2rem;
}

input, select {
  font-family: "Barlow Condensed", sans-serif;
  width: 80%;
  max-width: 30rem;
  font-size: 1.2rem;
  background-color: white;
  border: none;
  margin: 0 0 1rem 0;
  padding: 0.5rem;
}

button {
  width: 100%;
  max-width: 10rem;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.5rem;
  background-color: #a09879;
  border: none;
  padding: 0.5rem 2rem;
  margin: 0 0 2rem 0;
}

.question {
  width: 100%;
}
</style>
